
import {computed, defineComponent, ref} from "vue";
import {ErrorMessage, Field, Form} from "vee-validate";
import {hideModal} from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import airlineClassesTypes from "@/core/data/airlineclassestype";

interface newItemData {
  itemTitle: string;
  itemAirline: string;
  itemPriceCode: string;
  itemType: string;
}

export default defineComponent({
  name: "new-airline-modal",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  props: {
    airlineID: String,
  },
  data() {
    return {
    }
  },

  setup(props) {
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const newRoomModalRef = ref<null | HTMLElement>(null);

    const newItemData = ref<newItemData>({
      itemTitle: "",
      itemAirline: "",
      itemPriceCode: "",
      itemType: "",
    });

    const validationSchema = Yup.object().shape({
      itemTitle: Yup.string().required().label("Class Adı"),
      itemPriceCode: Yup.string().required().label("Ücret Kodu"),
      itemType: Yup.string().required().label("Class Türü"),
    });
    const submit = () => {
      if (!submitButtonRef.value) {
        return;
      }
      //Disable button
      submitButtonRef.value.disabled = true;
      // Activate indicator
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");


      const payload1 = {
        Title: newItemData.value.itemTitle,
        Airline: props.airlineID,
        PriceCode: newItemData.value.itemPriceCode,
        Type: newItemData.value.itemType,
      }
      //console.log(payload1);

      store.dispatch(Actions.AIRLINE_CLASSES_INSERT, payload1)
          .then(() => {
            Swal.fire({
              text: "Tebrikler. Başarılı bir şekilde ekleme işlemini tamamladınız.",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Geri Dön!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(function () {
              submitButtonRef.value.disabled = false;
              submitButtonRef.value.setAttribute("data-kt-indicator", "off");
              hideModal(newRoomModalRef.value);
              // Go to page after successfully login
              //router.push({name: "userlist"});
            });
          }).catch(() => {
        Swal.fire({
          text: store.getters.getAirlineClassesErrors[0],
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Tekrar dene!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
        submitButtonRef.value.disabled = false;
        submitButtonRef.value.setAttribute("data-kt-indicator", "off");
      });

    };



    return {
      newItemData,
      validationSchema,
      submit,
      submitButtonRef,
      newRoomModalRef,
      airlineClassesTypes,
    };
  },
});
