const airlineClassesTypes = {
  "TYPES" : [
    {
      Value : 'BUS',
      Title : 'BUSINESS',
    },
    {
      Value : 'ECO',
      Title : 'EKONOMİ',
    },
    {
      Value : 'PRO',
      Title : 'PROMOSYON',
    },
    {
      Value : 'SPC',
      Title : 'ÖZEL',
    },
  ],
};
export default airlineClassesTypes;
